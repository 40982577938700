body {
  margin: 0;
  padding: 0;
}

.bg-color-white {
  background-color: white;
}

.map-container {
  height: 100%;
  width: 100%;
}

.card {
  height: 100%;
}

.text-center {
  text-align: center;
}

.login-card {
  margin: 3rem auto 0;
  text-align: left;
  max-width: 300px;
}

.signin-card {
  margin: 3rem auto 0;
  text-align: left;
  max-width: 600px;
}

.login-form-button {
  width: 100%;
}

.signin-form-button {
  width: 100%;
}

.flex {
  display: flex;
}

.logo {
  align-self: center;
}
